import styles from './pie-chart-card.module.scss';
import { PieChart } from 'react-minimal-pie-chart';
import { v4 as uuidv4 } from 'uuid';
import Numeral from 'numeral';

/* eslint-disable-next-line */
export interface PieChartCardProps {
  cardTitle: string;
  unavailableMsg?: string;
  chartData: {
    title: string;
    value: number;
    key: number;
    color: string;
  }[];
}

export function PieChartCard({
  cardTitle,
  chartData,
  unavailableMsg,
}: PieChartCardProps) {
  return (
    <div className={`${styles['pie-chart-card']} card`}>
      <h1>{cardTitle}</h1>
      <div className={styles['content']}>
        {chartData.length ? (
          <div>
            <PieChart
              style={{
                width: '160px',
                height: '160px',
                border: '1px solid #003a704f',
                borderRadius: '50%',
                padding: '20px',
                boxShadow: '0px 15px 48px -12px rgba(0, 58, 112, 0.18)',
              }}
              className={styles['pie-chart']}
              startAngle={270}
              data={chartData}
            />
          </div>
        ) : (
          <h2>{unavailableMsg}</h2>
        )}

        <ul className={styles['info-list']}>
          {chartData.map((data) => {
            return (
              <li style={{ color: data.color }} key={uuidv4()}>
                <label>
                  {data.title}{' '}
                  <span>({Numeral(data.key).format('0,0.00')}%)</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default PieChartCard;
