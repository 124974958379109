import { useTranslation } from 'react-i18next';
import BarChart from '../bar-chart/bar-chart';
import { useState } from 'react';
import { getButtonText } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import { GraphProps } from '../../definitions/analytics';
// import { useSearchParams } from 'react-router-dom';

export function MostSearchesBreakdown(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const activeTabs = [
    'Most Searched Country',
    'Most Searched Destination',
    'Most Searched Unit Type',
  ];
  const [activeTab, setActiveTab] = useState<string>(activeTabs[0]);
  const [loading, setLoading] = useState(false);

  const labels: string[] = [];
  const chartData: { x: string; y: number }[] = [];

  const data = {
    labels,
    datasets: [
      {
        label: getButtonText(props.selectedDuration),
        data: chartData,
        backgroundColor: '#003A70',
        hoverBackgroundColor: '#4C759B',
        borderRadius: 8,
        borderSkipped: false,
        maxBarThickness: 30,
        categoryPercentage: 0.5,
        barPercentage: 1,
      },
    ],
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <BarChart
      description={t('mostSearchesBreakdownDescription')}
      title={t('mostSearchesBreakdown')}
      activeTabs={activeTabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      data={data}
      unMaintainAspectRatio
    />
  );
}

export default MostSearchesBreakdown;
