import loader from './orascom-loader.svg';
import styles from './loaderSmall.module.scss';

export function LoaderSmall() {
  return (
    <div className={`${styles['loader']} loader-wrapper`}>
      <img src={loader} alt="" role="presentation" />
    </div>
  );
}

export default LoaderSmall;
