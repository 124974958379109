import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Engagement as EngagementUtils } from '../../utils/engagement';
import { errorMessagesHandler } from '@orascom/utils';
import { Loader } from '@orascom/common-components';
import { useSearchParams } from 'react-router-dom';
import styles from './customer-demographics.module.scss';
import {
  LeadsPerCountry,
  PageViewsPerCountry,
  SalesPerCountry,
} from '../../definitions/interfaces/engagmenet';
import { GraphProps } from '../../definitions/analytics';
import PieChartCard from '../pie-chart-card/pie-chart-card';

export function CustomerDemographics(props: Readonly<GraphProps>) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [viewsBreakdownByCountry, setViewsBreakdownByCountry] =
    useState<PageViewsPerCountry[]>();
  const [salesBreakdownByCountry, setSalesBreakdownByCountry] =
    useState<SalesPerCountry[]>();
  const [leadsBreakdownByCountry, setLeadsBreakdownByCountry] =
    useState<LeadsPerCountry[]>();
  const [pageViewsLoading, setPageViewsLoading] = useState(true);
  const [demographicsLoading, setDemographicsLoading] = useState(true);
  const [leadsLoading, setLeadsLoading] = useState(true);

  const startTimeStamp = searchParams.get('start');
  const endTimeStamp = searchParams.get('end');

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
    };
    EngagementUtils.getAnalyticsPageViewsPerCountry(params)
      .then((res) => {
        setViewsBreakdownByCountry(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setPageViewsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp]);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
    };

    EngagementUtils.getAnalyticsSalesPerCountry(params)
      .then((res) => {
        setSalesBreakdownByCountry(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setDemographicsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp]);

  useEffect(() => {
    const params = {
      from: props.startDateArg,
      to: props.endDateArg,
    };

    EngagementUtils.getAnalyticsLeadsPerCountry(params)
      .then((res) => {
        setLeadsBreakdownByCountry(res);
      })
      .catch((errors) => {
        errorMessagesHandler(errors.data);
      })
      .finally(() => {
        setLeadsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimeStamp, endTimeStamp]);

  const chartColors = ['#003A70', '#D9E2EA', '#4C759B', '#021E37', '#B7D7EA'];

  const viewBreakdownChartData = viewsBreakdownByCountry?.map(
    (breakdown, index) => ({
      title: breakdown.country_name ?? t('other'),
      value: breakdown.views_count,
      key: breakdown.percentage,
      color: chartColors[index],
    })
  );

  const salesBreakdownChartData = salesBreakdownByCountry?.map(
    (breakdown, index) => ({
      title: breakdown.country_name ?? t('other'),
      value: breakdown.sales_count,
      key: breakdown.percentage,
      color: chartColors[index],
    })
  );

  const leadsBreakdownChartData = leadsBreakdownByCountry?.map(
    (breakdown, index) => ({
      title: breakdown.country_name ?? t('other'),
      value: breakdown.leads_count,
      key: breakdown.percentage,
      color: chartColors[index],
    })
  );

  if (demographicsLoading || pageViewsLoading || leadsLoading) {
    return <Loader />;
  }
  return (
    <div className={styles['wrapper']}>
      <h3 className="shoppes-analytics__sub-title">
        {t('customerDemographicsDescription')}
      </h3>
      <h2 className="shoppes-analytics__title">{t('customerDemographics')}</h2>
      <div className={styles['content']}>
        <PieChartCard
          cardTitle={t('viewsBreakdownPerCountry')}
          chartData={viewBreakdownChartData ?? []}
          unavailableMsg={t('noViewsBreakdownPerCountry')}
        />

        <PieChartCard
          cardTitle={t('salesBreakdownPerCountry')}
          chartData={salesBreakdownChartData ?? []}
          unavailableMsg={t('noSalesBreakdownPerCountry')}
        />

        <PieChartCard
          cardTitle={t('leadsBreakdownPerCountry')}
          chartData={leadsBreakdownChartData ?? []}
          unavailableMsg={t('noLeadsBreakdownPerCountry')}
        />
      </div>
    </div>
  );
}

export default CustomerDemographics;
