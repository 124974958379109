import { useTranslation } from 'react-i18next';
import LoginsOverTime from '../../components/logins-over-time/logins-over-time';
import {
  AnalyticsPeriodFilter,
  useAnalyticsFilters,
  useAnalyticsPeriodFilter,
} from '@orascom/broker-sales-man-common-components';
import MostViewsBreakdown from '../../components/most-views-breakdown/most-views-breakdown';
import MostSearchesBreakdown from '../../components/most-searches-breakdown.tsx/most-searches-breakdown';
import SearchesWithNoAvailability from '../../components/searches-with-no-availability/searches-with-no-availability';

export function CustomerBehavior() {
  const { t } = useTranslation();
  const { selectedDuration } = useAnalyticsPeriodFilter();

  const { startDateArg, endDateArg } = useAnalyticsFilters();

  return (
    <div className="analytics shopper-analytics">
      <div className="analytics__title">
        <h1>
          {' '}
          {t('engagement')}
          <b>
            {' / '} {t('customerBehavior')}
          </b>
        </h1>
      </div>
      <div className="shopper-analytics__content">
        <AnalyticsPeriodFilter portal="shopper-analytics" />
        <MostViewsBreakdown
          selectedDuration={selectedDuration}
          startDateArg={startDateArg}
          endDateArg={endDateArg}
        />
        <MostSearchesBreakdown
          selectedDuration={selectedDuration}
          startDateArg={startDateArg}
          endDateArg={endDateArg}
        />
        <SearchesWithNoAvailability
          selectedDuration={selectedDuration}
          startDateArg={startDateArg}
          endDateArg={endDateArg}
        />
        <LoginsOverTime
          selectedDuration={selectedDuration}
          startDateArg={startDateArg}
          endDateArg={endDateArg}
        />
      </div>
    </div>
  );
}

export default CustomerBehavior;
